import axios from "axios";
import { setError } from "../../redux/reducers";
export const apiBaseUrl = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: apiBaseUrl,
});

let controllers = [];

export const addController = () => {
  const controller = new AbortController();
  controllers.push(controller);
  return controller;
};

export const abortAll = () => {
  controllers.forEach((controller) => controller.abort());
  controllers = []; // Clear after aborting
  console.log("Aborted all API calls");
};

const API = async ({
  method,
  path,
  data = null,
  queryParams = null,
  customHeaders = {},
  dispatch,
  disableOverridePrompts = false,
  showErrorModal = true,
  signal = null,
  onFailureCallback = () => {},
  onSuccessCallback = () => {},
}) => {
  try {
    const { signal: globalSignal } = addController();
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    const selectedLLM = localStorage.getItem("selectedLLM");
    const editDefaultPrompt = localStorage.getItem("editDefaultPrompts");
    const headers = { ...customHeaders };

    if (storedUserData) {
      headers["Authorization"] = `Bearer ${storedUserData.token}`;
    }
    headers["Content-Type"] = "application/json";
    headers["llm-model"] = selectedLLM;
    if (!disableOverridePrompts) {
      headers["override-prompts"] = editDefaultPrompt;
    }

    if (queryParams) {
      const response = await api.request({
        method,
        url: path,
        data,
        params: queryParams,
        headers,
        signal: signal || globalSignal,
      });
      onSuccessCallback(response);
      return response;
    } else {
      const response = await api.request({
        method,
        url: path,
        data,
        headers,
        signal: signal || globalSignal,
      });
      onSuccessCallback(response);
      return response;
    }
  } catch (error) {
    onFailureCallback(error);
    if (error.code === "ERR_CANCELED") {
      return;
    }
    if (error.response) {
      const statusCode = error.response.status;
      if (statusCode === 401 || statusCode === 403) {
        // localStorage.removeItem("userData");
        // window.location.href = "/";
      } else {
        if (dispatch && showErrorModal) {
          dispatch(
            setError({
              error: true,
              message:
                error.response?.data?.message ||
                error.message ||
                "Error in API request",
            })
          );
        }
      }
    } else {
      console.log("Error", error.message);
      if (dispatch && showErrorModal) {
        dispatch(
          setError({
            error: true,
            message: error.message || "Error in API request",
          })
        );
      }
    }
  }
};

export default API;
