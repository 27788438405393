import React, { useState, useCallback, useMemo, memo, useEffect } from "react";
import { Card } from "antd";
import { useDispatch } from "react-redux";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";

import BreadCrumb from "../../components/breadcrumbComponent";
import InfoMessageComponent from "../../components/infoMessageComponent";
import TableComponent from "../../components/tableComponent";
import UploadFilesComponentV2 from "../../components/uploadFilesComponentV2";
import SingleProgressBarComponent from "../../components/SingleProgressBarComponent";
import BalanceSheetComponent from "../../components/balanceSheetComponent";
import IncomeSheetComponent from "../../components/incomeSheetComponent";

import { DecoderSelectSheetOption } from "./decoderSelectSheetOption";
import { DisplaySheetWithDetails } from "./displaySheetWithDetails";
import { DecoderStatusTrackSheetTable } from "./decoderStatusTrackSheetTable";

import Aries from "../../locale/en-Us.json";
import { radioSelectionListColumn } from "../../utils/columns";
import { tableLoading } from "../../utils/utils";
import { getButtonSelectEngagement } from "./helper";
import { useGetCikList } from "../ratiosVarianceMateriality/hooks";
import { getDecoderSheetDataApi, getDecoderTrackerApi } from "./api";
import {
  useGetSheetName,
  useGetSheetGuidance,
  useGetSheetGuidanceSubmit,
  checkGuidanceRequire,
} from "./hook";

import {
  decoderJsonV2,
  UploadSheetDecoderJsonV2,
  balanceSheetJson,
  incomeSheetJson,
  decoderGuidanceJson,
  incomeSheetGuidanceJson,
  balanceSheetGuidanceJson,
} from "../../stepesJsons/decoder";

import { dummysheetdata } from "./dummyData";

import "./decoder.scss";
import { EMPTY_LOADER_CONFIG, loaderTypes } from "../../utils/constants";
import { setloader } from "../../redux/reducers";
import { abortAll } from "../../utils/api/api";

const DecoderV2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState({
    cikNumber: {},
    step: 0,
    jsonSteps: decoderJsonV2,
    documentList: null,
    decoderSheetData: null,
    percent: 10,
    error: "",
    fileUploadResponse: "",
    selectSheet: [],
    saveSheetGuidanceData: null,
    sheetGuidancelist: [],
    decoderTrackerData: null,
    statementType: "",
    selectedSheetIndex: 0,
    period: "",
    guidanceStatus: false,
  });

  const { getSheetName } = useGetSheetName();
  const location = useLocation();
  const projectId = location.state?.project_id;
  const redirect = location.state?.redirect;
  const project = location.state?.project;
  const editPermission = location.state?.editPermission;
  const { getSheetguidance, sheetGuidance, setSheetGuidance } =
    useGetSheetGuidance();
  const { submitSheetguidance, submitedSheetResponse } =
    useGetSheetGuidanceSubmit();
  const { fetchCikNumberList, cikNumberList, loading } = useGetCikList(
    "PLANNING",
    "DECODING"
  );
  const [editAllowed, setEditAllowed] = useState(true);

  const handleRadioChange = useCallback((e, key) => {
    const cikNumberList = JSON.parse(e.target.value);
    setState((prevState) => ({
      ...prevState,
      [key]: cikNumberList,
      documentList: null,
      selectSheet: [],
      saveSheetGuidanceData: null,
      sheetGuidancelist: [],
    }));
    setEditAllowed(cikNumberList?.editAllowed);
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      dispatch(setloader({ showLoader: true, type: loaderTypes.FULL_SCREEN }));
      if (projectId) {
        setState({ cikNumber: project });
        try {
          const trackerData = await getDecoderTrackerApi(projectId, dispatch);
          if (isMounted) {
            setState((prevState) => ({
              ...prevState,
              decoderTrackerData: trackerData,
              jsonSteps: decoderJsonV2,
              step: 3,
            }));
            setEditAllowed(editPermission);
          }
        } catch (error) {
          console.error("Error fetching tracker data:", error);
        }
      } else {
        if (isMounted) {
          setState((prevState) => ({
            ...prevState,
            step: 1,
          }));
        }
      }
      if (isMounted) {
        dispatch(setloader(EMPTY_LOADER_CONFIG));
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, [projectId, dispatch, project, decoderJsonV2]);

  const nextStep = useCallback(
    async (step, hitApi) => {
      if (hitApi === "back_to_tracker") {
        const trackerData = await getDecoderTrackerApi(
          state.cikNumber.projectId,
          dispatch
        );
        setState((prevState) => ({
          ...prevState,
          decoderTrackerData: trackerData,
          jsonSteps: decoderJsonV2,
          step: 3,
        }));
      } else if (hitApi === "getGuidance") {
        getSheetguidance(state.fileUploadResponse?.id, state.selectSheet);
        setState((prevState) => ({ ...prevState, step: prevState.step + 1 }));
      } else if (hitApi === "trackSheetStatus") {
        dispatch(
          setloader({ showLoader: true, type: loaderTypes.FULL_SCREEN })
        );
        const trackerData = await getDecoderTrackerApi(
          state.cikNumber.projectId,
          dispatch
        );
        dispatch(setloader(EMPTY_LOADER_CONFIG));
        setState((prevState) => ({
          ...prevState,
          decoderTrackerData: trackerData,
          step: prevState.step + 1,
        }));
      } else if (hitApi === "submitGuidance") {
        dispatch(
          setloader({ showLoader: true, type: loaderTypes.FULL_SCREEN })
        );
        let data = "";
        if (state.guidanceStatus) {
          const dataResult = await submitSheetguidance(
            state.fileUploadResponse?.id,
            state.saveSheetGuidanceData
          );
          data = dataResult;
        }

        dispatch(setloader(EMPTY_LOADER_CONFIG));
        if (data || !state.guidanceStatus) {
          setState((prevState) => ({
            ...prevState,
            // saveSheetGuidanceData: null,
            jsonSteps:
              state.statementType === "BALANCE_SHEET"
                ? [...state.jsonSteps, ...balanceSheetGuidanceJson]
                : [...state.jsonSteps, ...incomeSheetGuidanceJson],
            step: prevState.step + 1,
          }));

          const decoderData = await getDecoderSheetDataApi(
            state.cikNumber.projectId,
            state.period,
            state.statementType,
            dispatch
          );
          if (decoderData) {
            if (
              (decoderData?.balanceSheet?.sheet?.rows &&
                state.statementType === "BALANCE_SHEET") ||
              (decoderData?.incomeStatementSheet?.sheet?.rows &&
                state.statementType === "INCOME_STATEMENT")
            ) {
              setState((prevState) => ({
                ...prevState,
                percent: 100,
                decoderSheetData: decoderData,
                step: prevState.step + 1,
              }));
            } else {
              setState((prevState) => ({
                ...prevState,
                percent: 100,
                error: Aries.DECODER_NO_SHEET_TEXT,
              }));
            }
          }
        }
      } else if (hitApi === "companyList") {
        fetchCikNumberList();
        setState((prevState) => ({ ...prevState, step: prevState.step + 1 }));
      }
    },
    [state, getSheetguidance, fetchCikNumberList, dispatch]
  );

  const backStep = useCallback(
    async (step, backstepNumber, hitapi) => {
      abortAll();
      if (hitapi === "refreshTrackApi") {
        const trackerData = await getDecoderTrackerApi(
          state.cikNumber.projectId
        );
        setState((prevState) => ({
          ...prevState,
          decoderTrackerData: trackerData,
          step: 3,
          jsonSteps: decoderJsonV2,
          selectSheet: [],
        }));
        return;
      } else if (hitapi === "getGuidance") {
        getSheetguidance(state.fileUploadResponse?.id, state.selectSheet);
      }
      setState((prevState) => ({
        ...prevState,
        step: prevState.step > 1 ? prevState.step - (backstepNumber || 1) : 1,
      }));
    },
    [state]
  );

  const checkGuidence = useCallback(
    async (projectId, period, statementType) => {
      dispatch(setloader({ showLoader: true, type: loaderTypes.FULL_SCREEN }));
      const result = await checkGuidanceRequire(
        projectId,
        period,
        statementType
      );
      // if (result) {
      setState((prevState) => ({
        ...prevState,
        jsonSteps: [...decoderJsonV2, ...decoderGuidanceJson],
        sheetGuidancelist: result?.sheetname,
        fileUploadResponse: result.feildData,
        statementType: statementType,
        period: period,
        step: prevState.step + 1,
      }));
      // }
      dispatch(setloader(EMPTY_LOADER_CONFIG));
      return result;
    },
    []
  );

  const getSheetguidanceData = useCallback(
    async (statementType, period) => {
      setState((prevState) => ({
        ...prevState,
        jsonSteps:
          statementType === "BALANCE_SHEET"
            ? [...decoderJsonV2, ...balanceSheetJson]
            : [...decoderJsonV2, ...incomeSheetJson],
        step: prevState.step + 1,
        percent: 10,
        decoderSheetData: null,
        statementType: statementType,
      }));

      const decoderData = await getDecoderSheetDataApi(
        state.cikNumber.projectId,
        period,
        statementType,
        dispatch
      );
      if (decoderData) {
        if (
          (decoderData?.balanceSheet?.sheet?.rows &&
            statementType === "BALANCE_SHEET") ||
          (decoderData?.incomeStatementSheet?.sheet?.rows &&
            statementType === "INCOME_STATEMENT")
        ) {
          setState((prevState) => ({
            ...prevState,
            percent: 100,
            decoderSheetData: decoderData,
            step: prevState.step + 1,
          }));
        } else {
          setTimeout(() => {
            setState((prevState) => ({
              ...prevState,
              percent: 100,
              error: Aries.DECODER_NO_SHEET_TEXT,
            }));
          }, [2000]);
        }
      }
    },
    [state.cikNumber.projectId, dispatch]
  );

  const handleOpenSheet = useCallback(
    async (statementType, period, source) => {
      if (source !== "Auditor Uploaded") {
        getSheetguidanceData(statementType, period);
        return;
      }
      const checkGuidenceRequired = await checkGuidence(
        state.cikNumber.projectId,
        period,
        statementType
      );
      setState((prevState) => ({
        ...prevState,
        guidanceStatus: checkGuidenceRequired?.data,
      }));

      // if (checkGuidenceRequired) {
      //   getSheetguidanceData(statementType, period);
      // }
    },
    [checkGuidence, getSheetguidanceData, state.cikNumber.projectId]
  );

  const updateDecoderSheetData = useCallback((data) => {
    setState((prevState) => ({
      ...prevState,
      decoderSheetData: data,
    }));
  }, []);

  const renderCommonBreadCrumb = useCallback(
    () => (
      <BreadCrumb
        mainTitle={Aries.BREADCRUMB_NAVIGATION_MAIN_TITLE}
        subTitle={Aries.DECODER_SCREEN_TITLE}
        title={Aries.DECODER_SCREEN_TITLE}
        margin={"24px 70px"}
      />
    ),
    []
  );

  const renderStepContent = useMemo(() => {
    const currentStep =
      state.jsonSteps && state.jsonSteps.find((s) => s.step === state.step);
    if (!currentStep) return null;

    return currentStep.fields.map((field) => {
      const commonProps = {
        key: field.name,
        label: field.label,
      };

      switch (field.name) {
        case "welcome":
          return (
            <React.Fragment key={field.name}>
              {renderCommonBreadCrumb()}
              <Card className={"card"}>
                <InfoMessageComponent
                  {...commonProps}
                  nextStep={() => nextStep(state.step, "companyList")}
                  backStep={() => backStep(state.step)}
                  page={field.name === "welcome" ? "/" : null}
                />
              </Card>
            </React.Fragment>
          );
        case "listWithRadio":
          return (
            <React.Fragment key={field.name}>
              {renderCommonBreadCrumb()}
              <Card className={"card"}>
                <TableComponent
                  {...commonProps}
                  defaultColumns={radioSelectionListColumn(
                    (e) => handleRadioChange(e, "cikNumber"),
                    state.cikNumber.projectId
                  )}
                  buttonGroup={getButtonSelectEngagement(
                    nextStep,
                    backStep,
                    navigate,
                    state
                  )}
                  data={cikNumberList}
                  buttonGroupClassname={"fixed-button-group"}
                  showTableHeaderIcons={false}
                  showDivider={false}
                  tableLoading={
                    loading
                      ? tableLoading({
                          loading,
                          text: Aries.DECODER_LOADER_SELECT_CLIENT,
                        })
                      : false
                  }
                  searchOption
                  tableClassName={"table"}
                  scroll={{ y: "25vh" }}
                />
              </Card>
            </React.Fragment>
          );
        case "decoderTrackerTable":
          return (
            <React.Fragment key={field.name}>
              <BreadCrumb
                mainTitle={`${Aries.BREADCRUMB_NAVIGATION_MAIN_TITLE} / ${Aries.DECODER_SCREEN_TITLE}`}
                subTitle={"Decoder"}
                margin={"0 24px"}
              />
              <DecoderStatusTrackSheetTable
                {...commonProps}
                decoderTrackerData={state.decoderTrackerData}
                companyName={state.cikNumber?.name}
                nextStep={(data) => nextStep(state.step)}
                backStep={() => backStep(state.step)}
                projectId={state.cikNumber.projectId}
                redirect={redirect}
                editAllowed={editAllowed}
                handleOpenSheet={(sheet, period, source) =>
                  handleOpenSheet(sheet, period, source)
                }
                uploadDecoderSheet={() =>
                  setState((prevState) => ({
                    ...prevState,
                    jsonSteps: UploadSheetDecoderJsonV2,
                    step: prevState.step + 1,
                  }))
                }
              />
            </React.Fragment>
          );
        case "selectSheetOptions":
          return (
            <React.Fragment key={field.name}>
              {renderCommonBreadCrumb()}
              <Card className={"card"}>
                <DecoderSelectSheetOption
                  {...commonProps}
                  sheetName={state.sheetGuidancelist}
                  nextStep={() => nextStep(state.step, "getGuidance")}
                  backStep={() => {
                    backStep(state.step);
                    setState((prevState) => ({
                      ...prevState,
                      percent: 10,
                      jsonSteps: decoderJsonV2,
                    }));
                  }}
                  statementType={state.statementType
                    .toLocaleLowerCase()
                    .replace("_", " ")}
                  checkedSheet={state.selectSheet}
                  handleSelectSheet={(response, sheetIndex) =>
                    setState((prevState) => ({
                      ...prevState,
                      selectSheet: response,
                      selectedSheetIndex: sheetIndex,
                    }))
                  }
                />
              </Card>
            </React.Fragment>
          );
        case "displayBalanceExcelwithDetails":
          return (
            <React.Fragment key={field.name}>
              <BreadCrumb
                mainTitle={`${Aries.BREADCRUMB_NAVIGATION_MAIN_TITLE} / ${Aries.DECODER_SCREEN_TITLE}`}
                subTitle={"Decoder"}
                margin={"0 24px"}
              />
              <DisplaySheetWithDetails
                {...commonProps}
                nextStep={(data) => nextStep(state.step, "submitGuidance")}
                backStep={() => backStep(state.step)}
                statementType={state.statementType}
                checkIsDetailsEdited={(response) => {
                  setState((prevState) => ({
                    ...prevState,
                    guidanceStatus: true,
                  }));
                }}
                submitSheetguidance={(response, data) => {
                  setState((prevState) => ({
                    ...prevState,
                    saveSheetGuidanceData: {
                      ...response,
                      [state.selectSheet[0]]: {
                        ...response[state.selectSheet[0]],
                        statementType: state.statementType,
                      },
                    },
                  }));
                  setSheetGuidance({ ...sheetGuidance, map: response });
                }}
                selectedSheetIndex={state.selectedSheetIndex}
                file={sheetGuidance?.base64Doc}
                sheetName={state.selectSheet && state.selectSheet}
                sheetGuidance={sheetGuidance?.map}
              />
            </React.Fragment>
          );

        case "uploadDecoderSheet":
          return (
            <React.Fragment key={field.name}>
              {renderCommonBreadCrumb()}
              <Card className={"card"}>
                <UploadFilesComponentV2
                  {...commonProps}
                  nextStep={() => nextStep(state.step, "back_to_tracker")}
                  api_url={`/v1/decoder/${state.cikNumber?.projectId}/upload/financial-statement`}
                  backStep={() => backStep(state.step)}
                  showBack={true}
                  showNext={true}
                  handleUploadfile={(response) =>
                    setState((prevState) => ({
                      ...prevState,
                      fileUploadResponse: response,
                    }))
                  }
                  inProgress
                  fileTypes={["xls", "xlsx"]}
                  fileFormatText={Aries.DECODER_UPLOAD_FILE_FILETYPES}
                  handleDisableNextButton={() => {}}
                  acceptedFileTypeText={"Documents accepted are xlsx "}
                />
              </Card>
            </React.Fragment>
          );
        case "loadingProgessBar":
          return (
            <React.Fragment key={field.name}>
              {renderCommonBreadCrumb()}
              <Card className={"card"}>
                <SingleProgressBarComponent
                  {...commonProps}
                  showNext={false}
                  backStep={() => {
                    backStep(state.step);
                    setState((prevState) => ({
                      ...prevState,
                      percent: 10,
                      error: false,
                      guidanceStatus: false,
                    }));
                  }}
                  error={state.error}
                  heading={Aries.DECODER_PROGRESS_BAR_TITLE}
                  subTitle={Aries.DECODER_PROGRESS_BAR_TITLE}
                  initialPercent={state.percent}
                />
              </Card>
            </React.Fragment>
          );
        case "balanceSheet":
          return (
            <React.Fragment key={field.name}>
              <BreadCrumb
                mainTitle={`${Aries.BREADCRUMB_NAVIGATION_MAIN_TITLE} / ${Aries.DECODER_SCREEN_TITLE}`}
                subTitle={"BalanceSheet"}
                margin={"0 24px"}
              />
              <BalanceSheetComponent
                {...commonProps}
                companyName={state.cikNumber.client?.name}
                decoderSheet={state.decoderSheetData}
                nextStep={() => nextStep(state.step, "saveDecoderSheetData")}
                cikNumber={state.cikNumber}
                editAllowed={editAllowed}
                updateDecoderSheetData={updateDecoderSheetData}
                backStep={(res) => {
                  backStep(state.step, 2, res);
                  setState((prevState) => ({
                    ...prevState,
                    percent: 10,
                    guidanceStatus: false,
                  }));
                }}
              />
            </React.Fragment>
          );
        case "incomeSheet":
          return (
            <React.Fragment key={field.name}>
              <BreadCrumb
                mainTitle={`${Aries.BREADCRUMB_NAVIGATION_MAIN_TITLE} / ${Aries.DECODER_SCREEN_TITLE}`}
                subTitle={"IncomeSheet"}
                margin={"0 24px"}
              />
              <IncomeSheetComponent
                {...commonProps}
                companyName={state.cikNumber?.client?.name}
                nextStep={(e) => nextStep(state.step, null, e)}
                backStep={(res) => {
                  backStep(state.step, 2, res);
                  setState((prevState) => ({
                    ...prevState,
                    percent: 10,
                    guidanceStatus: false,
                  }));
                }}
                editAllowed={editAllowed}
                cikNumber={state.cikNumber}
                updateDecoderSheetData={updateDecoderSheetData}
                decoderSheet={state.decoderSheetData}
              />
            </React.Fragment>
          );
        default:
          return null;
      }
    });
  }, [
    state,
    nextStep,
    backStep,
    handleRadioChange,
    cikNumberList,
    loading,
    navigate,
    renderCommonBreadCrumb,
    sheetGuidance,
    updateDecoderSheetData,
  ]);

  return <div className="decoder-Container">{renderStepContent}</div>;
};

export default React.memo(DecoderV2);
